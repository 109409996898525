/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: false, // Change this to false if you don't want Splash screen.
};

//SEO Related settings
const seo = {
  title: "Cristian's Portfolio",
  description:
  "Welcome to my website! I am an individual driven by passion and equipped with a robust background in Mathematics and Computer Science. Along my journey, I have finely crafted my skills across diverse technology frameworks, enabling me to navigate effortlessly through novel challenges in dynamic, fast-paced settings.",
  og: {
    title: "Cristian's Portfolio",
    type: "website",
    url: "https://cristianrlopez.com ",
  },
};

//Home Page
const greeting = {
  title: "Cristian R. Lopez",
  logo_name: "Cristian R. Lopez",
  subTitle:
  "Welcome to my website! I am an individual driven by passion and equipped with a robust background in Mathematics and Computer Science. Along my journey, I have finely crafted my skills across diverse technology frameworks, enabling me to navigate effortlessly through novel challenges in dynamic, fast-paced settings.",
  resumeLink:
    "https://drive.google.com/file/d/1Er-pznSUefkPkNEi_iAVF4gaxVk3wKhR/view"
};

const socialMediaLinks = [
  {
    name: "Github",
    link: "https://github.com/cristianrestrepolopez",
    fontAwesomeIcon: "fa-github", // Reference https://fontawesome.com/icons/github?style=brands
    backgroundColor: "#181717", // Reference https://simpleicons.org/?q=github
  },
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/cristianrestrepolopez/",
    fontAwesomeIcon: "fa-linkedin-in", // Reference https://fontawesome.com/icons/linkedin-in?style=brands
    backgroundColor: "#0077B5", // Reference https://simpleicons.org/?q=linkedin
  },
  {
    name: "Gmail",
    link: "mailto:cristianrestrepolopezgil@gmail.com",
    fontAwesomeIcon: "fa-google", // Reference https://fontawesome.com/icons/google?style=brands
    backgroundColor: "#D14836", // Reference https://simpleicons.org/?q=gmail
  },
  {
    name: "Facebook",
    link: "https://www.facebook.com/cris9781",
    fontAwesomeIcon: "fa-facebook-f", // Reference https://fontawesome.com/icons/facebook-f?style=brands
    backgroundColor: "#1877F2", // Reference https://simpleicons.org/?q=facebook
  }
];

const skills = {
  data: [
    {
      title: "Data Science & AI",
      fileName: "DataScienceImg",
      skills: [
        "⚡ Developing highly scalable production ready models for various use cases",
        "⚡ Experience of working with Deep Learning projects",
        "⚡ Sophisticated mathematical modeling to predict dynamic trends and perform comprehensive time series analysis.",
      ],
      softwareSkills: [
        {
          skillName: "Tensorflow",
          fontAwesomeClassname: "logos-tensorflow",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "PyTorch",
          fontAwesomeClassname: "logos-pytorch",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Python",
          fontAwesomeClassname: "ion-logo-python",
          style: {
            backgroundColor: "transparent",
            color: "#3776AB",
          },
        }
      ],
    },
    {
      title: "Cloud Infra-Architecture",
      fileName: "CloudInfraImg",
      skills: [
        "⚡ Proficient in handling various cloud environments",
        "⚡ Skillful in website hosting, maintenance, and database integration on virtual machines",
        "⚡ Expertise in configuring streaming tasks between databases and servers, leveraging GCP and AWS",
      ],
      softwareSkills: [
        {
          skillName: "AWS",
          fontAwesomeClassname: "simple-icons:amazonaws",
          style: {
            color: "#FF9900",
          },
        },
        {
          skillName: "GCP",
          fontAwesomeClassname: "skill-icons:gcp-light",
          style: {
            color: "#0089D6",
          },
        },
        {
          skillName: "PostgreSQL",
          fontAwesomeClassname: "simple-icons:postgresql",
          style: {
            color: "#336791",
          },
        },
        {
          skillName: "Docker",
          fontAwesomeClassname: "simple-icons:docker",
          style: {
            color: "#1488C6",
          },
        },
        {
          skillName: "Kubernetes",
          fontAwesomeClassname: "simple-icons:kubernetes",
          style: {
            color: "#326CE5",
          },
        },
      ],
    },
  ],
};

// Education Page
const competitiveSites = {
  competitiveSites: [
  ],
};

const degrees = {
  degrees: [
    {
      title: "The University of Massachusetts Lowell",
      subtitle: "BS In Computer Science - CyberSecurity Concentration, Math Minor",
      logo_path: "UML.png",
      alt_name: "",
      duration: "2019 - 2023",
      descriptions: [
        "Proficient in fundamental software engineering subjects, including Algorithms, DBMS, OS, NetSec, AI, and CC.",
        "⚡ Additionally, I have completed specialized courses in Machine Learning and Cloud Computing.",
        "⚡ I've been consecutively selected for the Dean's list for 3 years.",
        "⚡ Fluent in Spanish, English, and Portuguese",
      ],
      website_link: "https://www.uml.edu/",
    },
  ],
};

const certifications = {
  certifications: [
    {
      title: "Machine Learning",
      subtitle: "- Andrew Ng",
      logo_path: "stanford_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/verify/22SSC5WDTM",
      alt_name: "Stanford University",
      color_code: "#8C151599",
    },
    {
      title: "AWS Solutions Architect - Associate",
      subtitle: "- AWS Training",
      logo_path: "AWS-SAA.png",
      certificate_link:
        "https://www.credly.com/badges/74094063-41fe-4efd-a23f-6f58aef3872a/linked_in_profile",
      alt_name: "Amazon Web Services",
      color_code: "#4285F499",
    },
    {
      title: "AWS Certified Data Analytics - Specialty",
      subtitle: "- AWS Training",
      logo_path: "AWS-Data-Analytics-Specialty.png",
      certificate_link:
        "https://www.credly.com/badges/cf0e7a0d-bf32-4da4-b792-76c2ae73e2c0/public_url",
      alt_name: "Amazon Web Services",
      color_code: "#E6B8E699",
    },
    {
      title: "AWS Certified Cloud Practitioner",
      subtitle: "- AWS Training",
      logo_path: "AWS-Practitioner.png",
      certificate_link:
        "https://www.credly.com/badges/b032e841-be7e-4d22-80ac-b041490f8271/linked_in_profile",
      alt_name: "Amazon Web Services",
      color_code: "#00000099",
    },
  ],
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "",
  description: "", //Need to do
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work",
      work: true,
      experiences: [
        {
          title: "Assistant Manager and Waiter",
          company: "Davio’s Steak House",
          company_url: "https://legatohealthtech.com/",
          logo_path: "davios-log.png",
          duration: "Mar 2019 - May 2022",
          location: "Boston, MA - USA",
          description:
            "", // Need to do
          color: "#0879bf",
        },
      ],
    },
    {
      title: "Internships",
      experiences: [
        {
          title: "Cloud Support Associate",
          company: "Amazon Web Services",
          company_url: "https://aws.amazon.com/",
          logo_path: "AWS.png",
          duration: "Jun 2023 - Aug 2023",
          location: "Herndon, VA - USA",
          description:
            "", // Need to do
          color: "#000000",
        }
      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description: "My projects use a vast variety of technology tools. My current focus is in the experimentation of Data Analytics projects integrated with Cloud Computing Technologies.",
  avatar_image_path: "projects_image.svg",
};

const publicationsHeader = {
};

const publications = {
  data: [
  ],
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "cristian_logo.png",
    description:
      "I am available on almost every social media. However, I prefer to use LinkedIn for Academic/Professional endeavors.",
  },
  blogSection: {
    title: "Blogs",
    subtitle:
      "For individual fundamental empowerment, I like to write powerful lessons that create an impact on each of the reader individually to change the core of their character.",
    link: "",
    avatar_image_path: "blogs_image.svg",
  },
  addressSection: {
    title: "Current Location",
    subtitle:
      "Boston, MA - Risiding in Herndon, VA until Aug 2023",
    locality: "Boston",
    country: "US",
    region: "MA",
    postalCode: "02152",
    streetAddress: "",
    avatar_image_path: "address_image.svg",
    location_map_link: "",
  },
  phoneSection: {
    title: "",
    subtitle: "",
  },
};

export {
  settings,
  seo,
  greeting,
  socialMediaLinks,
  skills,
  competitiveSites,
  degrees,
  certifications,
  experience,
  projectsHeader,
  publicationsHeader,
  publications,
  contactPageData,
};
